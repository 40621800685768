import Vue from 'vue';
import Vuex from 'vuex';

import { announcement } from './announcement';
import { crafting } from './crafting';
import { encyclopedia } from './encyclopedia';
import { feats } from './feats';
import { forum } from './forum';
import { furniture } from './furniture';
import { global } from './global';
import { patreon } from './patreon';
import { quest } from './quest';
import { transaction } from './transaction';
import { user } from './user';

Vue.use(Vuex);

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    initialize() {
      this.dispatch('user/initialize');
    },
  },
  getters: {
    isInitializing(state, getters) {
      return getters['user/isLoadingProfile'];
    },
  },
  modules: {
    announcement,
    crafting,
    encyclopedia,
    feats,
    forum,
    furniture,
    global,
    patreon,
    quest,
    transaction,
    user,
  },
});

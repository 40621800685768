import { Patreon } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const patreon = {
  namespaced: true,
  state: {
    logs: [],
    unsynced: [],
    salaries: [],
    loadingLogs: false,
    loadingUnsynced: false,
    loadingSalaries: false,
  },
  mutations: {
    setLoadingLogs(state, loading) {
      state.loadingLogs = loading;
    },
    setLoadingUnsynced(state, loading) {
      state.loadingUnsynced = loading;
    },
    setLoadingSalaries(state, loading) {
      state.loadingSalaries = loading;
    },
    setLogs(state, data) {
      state.logs = data;
    },
    setUnsynced(state, data) {
      state.unsynced = data;
    },
    setSalaries(state, data) {
      state.salaries = data;
    },
  },
  actions: {
    loadLogs({ commit }, params) {
      commit('setLoadingLogs', true);

      return Patreon.getSyncLogs(params)
        .then((data) => {
          commit('setLogs', data);
        })
        .finally(() => {
          commit('setLoadingLogs', false);
        });
    },
    loadSalaries({ commit }, params) {
      commit('setLoadingSalaries', true);

      return Patreon.getSalaries(params)
        .then((data) => {
          commit('setSalaries', data);
        })
        .finally(() => {
          commit('setLoadingSalaries', false);
        });
    },
    loadUnsynced({ commit }) {
      commit('setLoadingUnsynced', true);

      return Patreon.getUnsynced()
        .then((data) => {
          commit('setUnsynced', data);
        })
        .finally(() => {
          commit('setLoadingUnsynced', false);
        });
    },
  },
};

import { Quest } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const quest = {
  namespaced: true,
  state: {
    selectedStory: null,
    selectedQuest: null,
    stories: [],
    quests: [],
    loadingStoryDetails: false,
    loadingQuestDetails: false,
    loadingStories: false,
    loadingQuests: false,
  },
  mutations: {
    setLoadingStories(state, loading) {
      state.loadingStories = loading;
    },
    setLoadingQuests(state, loading) {
      state.loadingQuests = loading;
    },
    setLoadingStoryDetails(state, loading) {
      state.loadingStoryDetails = loading;
    },
    setLoadingQuestDetails(state, loading) {
      state.loadingQuestDetails = loading;
    },
    setSelectedStory(state, data) {
      state.selectedStory = data;
    },
    setSelectedQuest(state, data) {
      state.selectedQuest = data;
    },
    setStories(state, data) {
      state.stories = data;
    },
    setQuests(state, data) {
      state.quests = data;
    },
  },
  actions: {
    loadStoryDetails({ commit }, id) {
      commit('setLoadingStoryDetails', true);

      return Quest.getStoryDetails(id)
        .then((data) => {
          commit('setSelectedStory', data);
        })
        .finally(() => {
          commit('setLoadingStoryDetails', false);
        });
    },
    loadQuestDetails({ commit }, id) {
      commit('setLoadingQuestDetails', true);

      return Quest.getQuestDetails(id)
        .then((data) => {
          commit('setSelectedQuest', data);
        })
        .finally(() => {
          commit('setLoadingQuestDetails', false);
        });
    },
    loadStories({ commit }, params) {
      commit('setLoadingStories', true);

      return Quest.getStories(params)
        .then((data) => {
          commit('setStories', data);
        })
        .finally(() => {
          commit('setLoadingStories', false);
        });
    },
    loadQuests({ commit }, params) {
      commit('setLoadingQuests', true);

      return Quest.getQuests(params)
        .then((data) => {
          commit('setQuests', data);
        })
        .finally(() => {
          commit('setLoadingQuests', false);
        });
    },
  },
};

<template>
  <div id="app">
    <navigation v-if="isAuthenticated"></navigation>

    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-2" v-if="isAuthenticated">
          <left-menu />
        </div>
        <div class="column">
          <router-view/>
        </div>
      </div>
    </div>

    <div class="container is-fluid is-paddingless">
      <div class="main-content">
        <copyright></copyright>
      </div>
    </div>
    <b-loading :is-full-page="true" :active="isInitializing || loadingGlobal"></b-loading>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Navigation from '@/components/Layout/Navigation.vue';
import Copyright from '@/components/Layout/Copyright.vue';
import LeftMenu from './components/Layout/LeftMenu.vue';

export default {
  components: {
    LeftMenu,
    Copyright,
    Navigation,
  },
  computed: {
    ...mapGetters(['isInitializing']),
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('global', ['loadingGlobal']),
  },
};
</script>

<style lang="scss">
  body {
    color: #222222;
    margin: 0;
  }

  h1,
  .title,
  .navbar-item,
  .glitch-font {
    font-family: 'Fredoka One', cursive !important;
    font-weight: 400 !important;
  }

  tr.is-danger {
    color: #bb0000;
    border: 1px solid #bb0000;
    background-color: #ffeeee !important;
  }

  .content p:not(:last-child) {
    margin-bottom: 0!important;
  }

  .clickable-table {
    tr {
      cursor: pointer;
    }
  }
</style>

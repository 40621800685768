// eslint-disable-next-line import/prefer-default-export
export const global = {
  namespaced: true,
  state: {
    loadingGlobal: false,
  },
  mutations: {
    setLoadingGlobal(state, loading) {
      state.loadingGlobal = loading;
    },
  },
  actions: {

  },
  getters: {

  },
};

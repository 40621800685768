import { Auth, User } from '@/services/api';
import TokenStorage from '@/services/token';

// eslint-disable-next-line import/prefer-default-export
export const user = {
  namespaced: true,
  state: {
    profile: null,
    selectedUser: null,
    users: [],
    loadingUser: false,
    loadingUsers: false,
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    },
    setLoadingUser(state, loading) {
      state.loadingUser = loading;
    },
    setLoadingUsers(state, loading) {
      state.loadingUsers = loading;
    },
    setSelectedUser(state, data) {
      state.selectedUser = data;
    },
    setUsers(state, data) {
      state.users = data;
    },
  },
  actions: {
    initialize({ dispatch }) {
      if (TokenStorage.isAuthenticated()) {
        dispatch('loadProfile');
      }
    },
    loadProfile({ commit }) {
      commit('global/setLoadingGlobal', true, { root: true });

      return User.getProfile()
        .then((data) => {
          commit('setProfile', data);
        })
        .finally(() => {
          commit('global/setLoadingGlobal', false, { root: true });
        });
    },
    loadUser({ commit }, id) {
      commit('setLoadingUser', true);

      return User.getUser(id)
        .then((data) => {
          commit('setSelectedUser', data);
        })
        .finally(() => {
          commit('setLoadingUser', false);
        });
    },
    loadUsers({ commit }, params) {
      commit('setLoadingUsers', true);

      return User.getUsers(params)
        .then((data) => {
          commit('setUsers', data);
        })
        .finally(() => {
          commit('setLoadingUsers', false);
        });
    },
    logout({ commit }) {
      commit('global/setLoadingGlobal', true, { root: true });

      if (TokenStorage.isAuthenticated()) {
        Auth.logout().finally(() => {
          TokenStorage.clear();
          commit('setProfile', null);
          commit('global/setLoadingGlobal', false, { root: true });
        });
      } else {
        TokenStorage.clear();
        commit('setProfile', null);
        commit('global/setLoadingGlobal', false, { root: true });
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.profile !== null;
    },
    isLoadingProfile(state) {
      return state.loadingProfile;
    },
    profile(state) {
      return state.profile;
    },
  },
};

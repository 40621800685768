import { Furniture } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const furniture = {
  namespaced: true,
  state: {
    selectedFurniture: null,
    furniture: [],
    furniture_sales: [],
    loadingFurnitureDetails: false,
    loadingFurniture: false,
    loadingFurnitureSales: false,
  },
  mutations: {
    setLoadingFurnitureSales(state, loading) {
      state.loadingFurnitureSales = loading;
    },
    setLoadingFurniture(state, loading) {
      state.loadingFurniture = loading;
    },
    setLoadingFurnitureDetails(state, loading) {
      state.loadingFurnitureDetails = loading;
    },
    setSelectedFurniture(state, data) {
      state.selectedFurniture = data;
    },
    setFurniture(state, data) {
      state.furniture = data;
    },
    setFurnitureSales(state, data) {
      state.furniture_sales = data;
    },
  },
  actions: {
    loadFurnitureDetails({ commit }, id) {
      commit('setLoadingFurnitureDetails', true);

      return Furniture.getFurnitureDetails(id)
        .then((data) => {
          commit('setSelectedFurniture', data);
        })
        .finally(() => {
          commit('setLoadingFurnitureDetails', false);
        });
    },
    loadFurniture({ commit }, params) {
      commit('setLoadingFurniture', true);

      return Furniture.getFurnitureItems(params)
        .then((data) => {
          commit('setFurniture', data);
        })
        .finally(() => {
          commit('setLoadingFurniture', false);
        });
    },
    loadFurnitureSales({ commit }, params) {
      commit('setLoadingFurnitureSales', true);

      return Furniture.getFurnitureSales(params)
        .then((data) => {
          commit('setFurnitureSales', data);
        })
        .finally(() => {
          commit('setLoadingFurnitureSales', false);
        });
    },
  },
};

<template>
  <div class="container">
    <section class="section">
      <h3 class="is-3">This is the place!</h3>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>

<style scoped lang="scss">

</style>

import { Feat } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const feats = {
  namespaced: true,
  state: {
    selectedEpic: null,
    selectedFeat: null,
    epicsData: [],
    featsData: [],
    loadingEpicDetails: false,
    loadingEpics: false,
    loadingFeats: false,
    loadingFeatDetails: false,
  },
  mutations: {
    setLoadingEpics(state, loading) {
      state.loadingEpics = loading;
    },
    setLoadingEpicDetails(state, loading) {
      state.loadingEpicDetails = loading;
    },
    setLoadingFeats(state, loading) {
      state.loadingFeats = loading;
    },
    setLoadingFeatDetails(state, loading) {
      state.loadingFeatDetails = loading;
    },
    setSelectedFeat(state, data) {
      state.selectedFeat = data;
    },
    setSelectedEpic(state, data) {
      state.selectedEpic = data;
    },
    setFeatsData(state, data) {
      state.featsData = data;
    },
    setEpicsData(state, data) {
      state.epicsData = data;
    },
  },
  actions: {
    loadFeatDetails({ commit }, id) {
      commit('setLoadingFeatDetails', true);

      return Feat.getFeatDetails(id)
        .then((data) => {
          commit('setSelectedFeat', data);
        })
        .finally(() => {
          commit('setLoadingFeatDetails', false);
        });
    },

    loadEpicDetails({ commit }, id) {
      commit('setLoadingEpicDetails', true);

      return Feat.getEpicDetails(id)
        .then((data) => {
          commit('setSelectedEpic', data);
        })
        .finally(() => {
          commit('setLoadingEpicDetails', false);
        });
    },
    loadFeats({ commit }) {
      commit('setLoadingFeats', true);

      return Feat.getAllFeats()
        .then((data) => {
          commit('setFeatsData', data);
        })
        .finally(() => {
          commit('setLoadingFeats', false);
        });
    },
    loadEpics({ commit }) {
      commit('setLoadingEpics', true);

      return Feat.getEpics()
        .then((data) => {
          commit('setEpicsData', data);
        })
        .finally(() => {
          commit('setLoadingEpics', false);
        });
    },
  },
};

import Api from './api';

export default {
  getEpics: () => Api.get('epics'),
  createEpic: (data) => Api.post('epics', data),
  updateEpic: (id, data) => Api.post(`epics/${id}`, data),
  getEpicDetails: (id) => Api.get(`epics/${id}`),
  createFeat: (id, data) => Api.post(`epics/${id}/feats`, data),
  updateFeat: (featId, data) => Api.post(`epics/feats/${featId}`, data),
  getFeatDetails: (featId) => Api.get(`epics/feats/${featId}`),
  getCurrentFeat: () => Api.get('epics/current'),
  getAllFeats: () => Api.get('epics/feats'),
};

<template>
  <b-navbar
    fixed-top
    shadow
  >
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <h4 class="title is-4 has-text-danger">ADMIN</h4>
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: '/config' }">Config</b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/tools' }">Tools</b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <button class="button is-primary" @click="logout">Logout</button>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { Feat } from '@/services/api';

export default {
  name: 'navigation',
  data() {
    return {
      currentFeat: null,
    };
  },
  mounted() {
    this.getCurrentFeat();
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
    },
    getCurrentFeat() {
      Feat.getCurrentFeat()
        .then((feat) => {
          this.currentFeat = feat;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>

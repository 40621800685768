import { Forum } from '@/services/api';

function updateTopic(commit, topicId, data) {
  commit('setUpdatingTopic', true);

  return Forum.updateTopic(topicId, data)
    .then((newTopic) => {
      if (parseInt(newTopic.id, 10) === parseInt(topicId, 10)) {
        commit('setSelectedTopic', newTopic);
      }
    })
    .finally(() => {
      commit('setUpdatingTopic', false);
    });
}

function updateComment(commit, commentId, data) {
  commit('setUpdatingComment', true);

  return Forum.updateComment(commentId, data)
    .then((newComment) => {
      commit('updateSingleComment', newComment);
    })
    .finally(() => {
      commit('setUpdatingComment', false);
    });
}

// eslint-disable-next-line import/prefer-default-export
export const forum = {
  namespaced: true,
  state: {
    comments: [],
    forums: [],
    loadingForum: false,
    loadingForums: false,
    loadingComments: false,
    loadingTopic: false,
    loadingTopics: false,
    selectedForum: null,
    selectedTopic: null,
    updatingTopic: false,
    updatingComment: false,
    topics: [],
    unapprovedTopics: [],
    unapprovedComments: [],
  },
  mutations: {
    setComments(state, comments) {
      state.comments = comments;
    },
    setForums(state, forums) {
      state.forums = forums;
    },
    setLoadingComments(state, loading) {
      state.loadingComments = loading;
    },
    setLoadingForum(state, loading) {
      state.loadingForum = loading;
    },
    setLoadingForums(state, loading) {
      state.loadingForums = loading;
    },
    setLoadingTopic(state, loading) {
      state.loadingTopic = loading;
    },
    setLoadingTopics(state, loading) {
      state.loadingTopics = loading;
    },
    setSelectedForum(state, selectedForum) {
      state.selectedForum = selectedForum;
    },
    setSelectedTopic(state, selectedTopic) {
      state.selectedTopic = selectedTopic;
    },
    setTopics(state, topics) {
      state.topics = topics;
    },
    setUnapprovedTopics(state, topics) {
      state.unapprovedTopics = topics;
    },
    setUnapprovedComments(state, comments) {
      state.unapprovedComments = comments;
    },
    setUpdatingTopic(state, updating) {
      state.updatingTopic = updating;
    },
    setUpdatingComment(state, updating) {
      state.updatingComment = updating;
    },
    updateSingleComment(state, comment) {
      state.comments.data = state.comments.data.map((item) => (parseInt(item.id, 10) === parseInt(comment.id, 10) ? comment : item));
    },
  },
  actions: {
    async loadForum({ commit, state }, forumSlug) {
      if (state.selectedForum && state.selectedForum.slug === forumSlug) {
        return;
      }

      commit('setLoadingForum', true);

      await Forum.getForumDetails(forumSlug)
        .then((data) => {
          commit('setSelectedForum', data);
        })
        .finally(() => {
          commit('setLoadingForum', false);
        });
    },
    loadForums({ commit, state }) {
      if (state.forums.length) {
        return new Promise(() => { });
      }

      commit('setLoadingForums', true);

      return Forum.getAllForums()
        .then((data) => {
          commit('setForums', data);
        })
        .finally(() => {
          commit('setLoadingForums', false);
        });
    },
    loadComments({ commit, state }, page) {
      commit('setLoadingComments', true);

      return Forum.getTopicComments(state.selectedForum.slug, state.selectedTopic.id, page)
        .then((data) => {
          commit('setComments', data);
        })
        .finally(() => {
          commit('setLoadingComments', false);
        });
    },
    loadTopic({ commit, state }, topicId) {
      if (state.selectedTopic && state.selectedTopic.id === topicId) {
        return new Promise(() => { });
      }

      commit('setLoadingTopic', true);

      return Forum.getTopicDetails(state.selectedForum.slug, topicId)
        .then((data) => {
          commit('setSelectedTopic', data);
        })
        .finally(() => {
          commit('setLoadingTopic', false);
        });
    },
    loadTopics({ commit, state }, page) {
      commit('setLoadingTopics', true);

      return Forum.getTopics(state.selectedForum.slug, page)
        .then((data) => {
          commit('setTopics', data);
        })
        .finally(() => {
          commit('setLoadingTopics', false);
        });
    },
    loadUnapprovedTopics({ commit }, page) {
      commit('setLoadingTopics', true);

      return Forum.getUnapprovedTopics(page)
        .then((data) => {
          commit('setTopics', data);
        })
        .finally(() => {
          commit('setLoadingTopics', false);
        });
    },
    loadUnapprovedComments({ commit }, page) {
      commit('setLoadingComments', true);

      return Forum.getUnapprovedComments(page)
        .then((data) => {
          commit('setComments', data);
        })
        .finally(() => {
          commit('setLoadingComments', false);
        });
    },
    unselectForum({ commit }) {
      commit('setSelectedForum', null);
    },
    deleteTopic({ commit }, topicId) {
      commit('setUpdatingTopic', true);

      return Forum.deleteTopic(topicId)
        .finally(() => {
          commit('setUpdatingTopic', false);
        });
    },
    deleteComment({ commit, state }, commentId) {
      commit('setUpdatingComment', true);

      return Forum.deleteComment(commentId)
        .then(() => {
          state.comments.data = state.comments.data.filter((item) => item.id !== commentId);
        })
        .finally(() => {
          commit('setUpdatingComment', false);
        });
    },
    closeTopic({ commit }, topicId) {
      updateTopic(commit, topicId, {
        closed: true,
      });
    },
    openTopic({ commit }, topicId) {
      updateTopic(commit, topicId, {
        closed: false,
      });
    },
    updateTopic({ commit }, data) {
      updateTopic(commit, data.id, data);
    },
    approveComment({ commit }, commentId) {
      updateComment(commit, commentId, {
        approved: true,
      });
    },
    unapproveComment({ commit }, commentId) {
      updateComment(commit, commentId, {
        approved: false,
      });
    },
    updateComment({ commit }, data) {
      updateComment(commit, data.id, data);
    },

    deleteCommentPhoto({ commit, state }, payload) {
      commit('setUpdatingComment', true);

      return Forum.deletePhoto(payload.photoId)
        .then(() => {
          state.comments.data = state.comments.data.map((item) => {
            if (item.id === payload.commentId) {
              return {
                ...item,
                photos: item.photos.filter((photo) => photo.id !== payload.photoId),
              };
            }

            return item;
          });
        })
        .finally(() => {
          commit('setUpdatingComment', false);
        });
    },
    deleteTopicPhoto({ commit, state }, photoId) {
      commit('setUpdatingTopic', true);

      return Forum.deletePhoto(photoId)
        .then(() => {
          state.selectedTopic.photos = state.selectedTopic.photos.filter((photo) => photo.id !== photoId);
        })
        .finally(() => {
          commit('setUpdatingTopic', false);
        });
    },
  },
};

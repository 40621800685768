import { Announcement } from '@/services/api';

// eslint-disable-next-line import/prefer-default-export
export const announcement = {
  namespaced: true,
  state: {
    announcements: [],
    announcement: null,
    loadingAnnouncement: false,
    loadingAnnouncements: false,
    updatingAnnouncement: false,
  },
  mutations: {
    setAnnouncements(state, announcements) {
      state.announcements = announcements;
    },
    setAnnouncement(state, details) {
      state.announcement = details;
    },
    setLoadingAnnouncement(state, loading) {
      state.loadingAnnouncement = loading;
    },
    setLoadingAnnouncements(state, loading) {
      state.loadingAnnouncements = loading;
    },
    setUpdatingAnnouncement(state, updating) {
      state.updatingAnnouncement = updating;
    },
    removeAnnouncement(state, id) {
      state.announcements.data = state.announcements.data.filter((item) => item.id !== id);
    },
  },
  actions: {
    async loadAnnouncement({ commit }, announcementId) {
      commit('setLoadingAnnouncement', true);

      await Announcement.getAnnouncementDetails(announcementId)
        .then((data) => {
          commit('setAnnouncement', data);
        })
        .finally(() => {
          commit('setLoadingAnnouncement', false);
        });
    },
    loadAnnouncements({ commit }, page) {
      commit('setLoadingAnnouncements', true);

      return Announcement.getAllAnnouncements(page)
        .then((data) => {
          commit('setAnnouncements', data);
        })
        .finally(() => {
          commit('setLoadingAnnouncements', false);
        });
    },
    deleteAnnouncement({ commit }, announcementId) {
      commit('setUpdatingAnnouncement', true);

      return Announcement.deleteAnnouncement(announcementId)
        .then(() => {
          commit('removeAnnouncement', announcementId);
        })
        .finally(() => {
          commit('setUpdatingAnnouncement', false);
        });
    },
    updateAnnouncement({ commit }, data) {
      commit('setUpdatingAnnouncement', true);

      return Announcement.updateAnnouncement(data.id, data)
        .then((newAnnouncement) => {
          commit('setAnnouncement', newAnnouncement);
        })
        .finally(() => {
          commit('setUpdatingAnnouncement', false);
        });
    },
  },
};

import Api from './api';

export default {
  getGenerators: () => Api.get('crafting/generators'),
  getGeneratorItems: () => Api.get('crafting/generators/items'),
  getItems: (data) => Api.get('crafting/items', data),
  addItem: (data) => Api.post('crafting/items', data),
  updateItem: (id, data) => Api.post(`crafting/items/${id}`, data),
  getTasks: () => Api.get('crafting/tasks'),
  addTask: (data) => Api.post('crafting/tasks', data),
  updateTask: (id, data) => Api.post(`crafting/tasks/${id}`, data),
};

import Api from './api';

export default {
  getAllCategories: () => Api.get('encyclopedia/categories'),
  getSkillsByCategory: (category) => Api.get(`encyclopedia/skills/${category}`),
  getSkill: (skill) => Api.get(`encyclopedia/skill/${skill}`),
  getAchievementsByCategory: (category) => Api.get(`encyclopedia/achievements/${category}`),
  getAchievement: (achievement) => Api.get(`encyclopedia/achievement/${achievement}`),
  getLocationsByRegion: (region) => Api.get(`encyclopedia/locations/${region}`),
  getLocation: (location) => Api.get(`encyclopedia/location/${location}`),
  getGiant: (giant) => Api.get(`encyclopedia/giant/${giant}`),
  getUpgradesByCategory: (category) => Api.get(`encyclopedia/upgrades/${category}`),
  getUpgrade: (upgrade) => Api.get(`encyclopedia/upgrade/${upgrade}`),
  getItemsByCategory: (category) => Api.get(`encyclopedia/items/${category}`),
  getItem: (item) => Api.get(`encyclopedia/item/${item}`),
  getInhabitantsByCategory: (category) => Api.get(`encyclopedia/inhabitants/${category}`),
  getInhabitant: (inhabitant) => Api.get(`encyclopedia/inhabitant/${inhabitant}`),
  search: (keyword) => Api.get(`encyclopedia/search?q=${keyword}`),
};

<template>
  <footer class="footer">
    <div class="content has-text-centered has-text-black">
      <p>
        © {{ currentYear }} <strong>Odd Giants</strong>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'copyright',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

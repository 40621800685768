import { Transaction } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const transaction = {
  namespaced: true,
  state: {
    transactions: [],
    loadingTransactions: false,
  },
  mutations: {
    setLoadingTransactions(state, loading) {
      state.loadingTransactions = loading;
    },
    setTransactions(state, data) {
      state.transactions = data;
    },
  },
  actions: {
    loadTransactions({ commit }, params) {
      commit('setLoadingTransactions', true);

      return Transaction.getTransactions(params)
        .then((data) => {
          commit('setTransactions', data);
        })
        .finally(() => {
          commit('setLoadingTransactions', false);
        });
    },
  },
};

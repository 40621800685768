import Api from './api';

export default {
  // Public routes
  getAllForums: () => Api.get('forums'),
  getForumDetails: (forumId) => Api.get(`forums/${forumId}`),
  getTopics: (forumId, page) => Api.get(`forums/${forumId}/topics?page=${page}`),
  getTopicDetails: (forumId, topicId) => Api.get(`forums/${forumId}/topics/${topicId}`),
  getTopicComments: (forumId, topicId, page) => Api.get(`forums/${forumId}/topics/${topicId}/comments?page=${page}`),
  getUnapprovedTopics: (page) => Api.get(`forums/unapproved/topics?page=${page}`),
  getUnapprovedComments: (page) => Api.get(`forums/unapproved/comments?page=${page}`),

  // Auth routes
  createTopic: (forumSlug, title, text, subscribe, attachments) => {
    const formData = new FormData();

    formData.append('subscribe', subscribe);
    formData.append('title', title);
    formData.append('text', text);

    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment);
    });

    return Api.formDataPost(`forums/${forumSlug}/topics`, formData);
  },

  createComment: (forumSlug, topicId, text, subscribe, attachments) => {
    const formData = new FormData();

    formData.append('subscribe', subscribe);
    formData.append('text', text);

    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment);
    });

    return Api.formDataPost(`forums/${forumSlug}/topics/${topicId}/comments`, formData);
  },

  updateTopic: (topicId, data) => Api.post(`forums/topics/${topicId}`, data),
  deleteTopic: (topicId) => Api.delete(`forums/topics/${topicId}`),

  updateComment: (commentId, data) => Api.post(`forums/comments/${commentId}`, data),
  deleteComment: (commentId) => Api.delete(`forums/comments/${commentId}`),

  deletePhoto: (photoId) => Api.delete(`forums/photos/${photoId}`),
};

import { Crafting } from '../services/api';

// eslint-disable-next-line import/prefer-default-export
export const crafting = {
  namespaced: true,
  state: {
    generators: [],
    items: [],
    loadingGenerators: false,
    loadingItems: false,
  },
  mutations: {
    setLoadingGenerators(state, loading) {
      state.loadingGenerators = loading;
    },
    setLoadingItems(state, loading) {
      state.loadingItems = loading;
    },
    setGenerators(state, data) {
      state.generators = data;
    },
    setItems(state, data) {
      state.items = data;
    },
    addItem(state, item) {
      state.items.push(item);
    },
  },
  actions: {
    loadGenerators({ commit }) {
      commit('setLoadingGenerators', true);

      return Crafting.getGenerators()
        .then((data) => {
          commit('setGenerators', data);
        })
        .finally(() => {
          commit('setLoadingGenerators', false);
        });
    },
    loadItems({ commit }) {
      commit('setLoadingItems', true);

      return Crafting.getItems()
        .then((data) => {
          commit('setItems', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    addItem({ commit }, item) {
      commit('addItem', item);
    },
  },
};

import Api from './api';

export default {
  getProfile: () => Api.get('users'),
  getUsers: (data) => Api.get('users/all', data),
  getUser: (id) => Api.get(`users/${id}`),
  updateUser: (id, data) => Api.post(`users/${id}`, data),
  getHomeStreets: (id) => Api.get(`users/${id}/homestreets`),
  deleteHomestreet: (id, homestreet) => Api.delete(`users/${id}/homestreets/${homestreet}`),
  getLogFiles: (id) => Api.get(`users/${id}/logfiles`),
  getLogs: (id, date) => Api.get(`users/${id}/logfiles/${date}`),
  getAdditionalData: (id) => Api.get(`users/${id}/additional`),
  getPatreonActions: (id) => Api.get(`users/${id}/patreonActions`),
  getEarnTransactions: (id) => Api.get(`users/${id}/earnTransactions`),
  getFeats: (id) => Api.get(`users/${id}/feats`),
  getFurniture: (id) => Api.get(`users/${id}/furniture`),
  getInventory: (id) => Api.get(`users/${id}/inventory`),
};

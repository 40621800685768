<template>
  <b-menu :accordion="false" style="margin-top: 25px;" :activable="false">
    <b-menu-list
      v-for="category in menuItems"
      :key="category.label"
      :label="category.label"
    >
      <b-menu-item
        v-for="item in category.children"
        :key="item.label"
        :label="item.label"
        :tag="item.link ? 'router-link' : 'a'"
        :to="item.link || null"
        :icon="item.icon"
        :active="(item.link && $route.path === item.link) || (item.children && item.children.find(child => child.link === $route.path) !== undefined)"
        expanded
      >
        <b-menu-item
          v-for="child in item.children"
          :key="child.label"
          :label="child.label"
          :to="child.link"
          tag="router-link"
          :active="$route.path === child.link"
        />
      </b-menu-item>
    </b-menu-list>
  </b-menu>
</template>

<script>
import { mapState } from 'vuex';
import { Feat } from '@/services/api';

export default {
  name: 'left-menu',
  computed: {
    ...mapState('forum', ['loadingForums', 'forums', 'selectedForum']),
    menuItems() {
      const menuItems = {
        ...this.menu,
      };
      const forumItems = [];

      if (this.forums) {
        this.forums.forEach((forum) => {
          forumItems.push({
            link: `/forums/${forum.slug}`,
            label: `${forum.title} (${forum.topics_count})`,
          });
        });
      }

      menuItems[0].children[1].children = [
        ...menuItems[0].children[1].children,
        ...forumItems,
      ];

      return menuItems;
    },
  },
  data() {
    return {
      currentFeat: null,
      menu: [
        {
          label: 'Manage',
          children: [
            {
              label: 'Users',
              icon: 'users',
              link: '/users',
            },
            {
              label: 'Forums',
              icon: 'comment',
              children: [
                {
                  label: 'All',
                  link: '/forums',
                },
                {
                  label: 'Unapproved',
                  link: '/forums/unapproved',
                },
              ],
            },
          ],
        },
        {
          label: 'Reports',
          children: [
            {
              label: 'Transactions',
              icon: 'money-bill',
              link: '/transactions',
            },
            {
              label: 'Furniture',
              icon: 'chair',
              children: [
                {
                  label: 'Items',
                  link: '/furniture',
                },
                {
                  label: 'Sales',
                  link: '/furniture-sales',
                },
              ],
            },
            {
              label: 'Patreon',
              icon: 'dollar-sign',
              children: [
                {
                  label: 'Salaries',
                  link: '/patreon/salaries',
                },
                {
                  label: 'Sync Logs',
                  link: '/patreon/logs',
                },
                {
                  label: 'Unsynced',
                  link: '/patreon/unsynced',
                },
              ],
            },
          ],
        },
        {
          label: 'Game',
          children: [
            {
              label: 'Announcements',
              icon: 'bullhorn',
              link: '/announcements',
            },
            {
              label: 'Feats',
              icon: 'gift',
              children: [
                {
                  label: 'Epics',
                  link: '/epics',
                },
                {
                  label: 'Feats',
                  link: '/feats',
                },
              ],
            },
            {
              label: 'Quests',
              icon: 'gamepad',
              children: [
                {
                  label: 'Stories',
                  link: '/stories',
                },
                {
                  label: 'Quests',
                  link: '/quests',
                },
              ],
            },
          ],
        },
        {
          label: 'Crafting',
          children: [
            {
              label: 'Generators',
              icon: 'bolt',
              link: '/crafting/generators',
            },
            {
              label: 'Items',
              icon: 'hammer',
              link: '/crafting/items',
            },
            {
              label: 'Tasks',
              icon: 'check',
              link: '/crafting/tasks',
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('forum/loadForums');
    this.getCurrentFeat();
  },
  methods: {
    isForumSelected(forumSlug) {
      return this.selectedForum && forumSlug === this.selectedForum.slug;
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
    getCurrentFeat() {
      Feat.getCurrentFeat()
        .then((feat) => {
          this.currentFeat = feat;
        });
    },
  },
};
</script>

<style lang="scss">
.menu-list a.is-active {
  background-color: #dcdcdc !important;
  color: #444 !important;
  font-weight: bold;
}
</style>

import Api from './api';

export default {
  getStories: () => Api.get('stories'),
  getStoryDetails: (id) => Api.get(`stories/${id}`),
  createStory: (data) => Api.post('stories', data),
  updateStory: (id, data) => Api.post(`stories/${id}`, data),
  getQuests: (data) => Api.get('quests', data),
  getQuestDetails: (id) => Api.get(`quests/${id}`),
  createQuest: (data) => Api.post('quests', data),
  updateQuest: (id, data) => Api.post(`quests/${id}`, data),
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/middleware/auth';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/forums',
    name: 'Forums Home',
    component: () => import(/* webpackChunkName: "forums/home" */ '../views/Forum/Home.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/forums/unapproved',
    name: 'Unapproved',
    component: () => import(/* webpackChunkName: "forums/unapproved" */ '../views/Forum/Unapproved.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/forums/:forum',
    name: 'Forum Details',
    props: true,
    component: () => import(/* webpackChunkName: "forums/details" */ '../views/Forum/ForumDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/forums/:forum/:topic',
    name: 'Topic Details',
    props: true,
    component: () => import(/* webpackChunkName: "forums/topic" */ '../views/Forum/TopicDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/feats',
    name: 'Feats',
    component: () => import(/* webpackChunkName: "feats" */ '../views/Feats/Feats.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/feats/:feat',
    name: 'Feat Details',
    props: true,
    component: () => import(/* webpackChunkName: "feats/details" */ '../views/Feats/FeatDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/epics',
    name: 'Epics',
    component: () => import(/* webpackChunkName: "epics" */ '../views/Feats/Epics.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/epics/:epic',
    name: 'Epic Details',
    props: true,
    component: () => import(/* webpackChunkName: "epics/details" */ '../views/Feats/EpicDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/patreon/logs',
    name: 'Patreon Logs',
    component: () => import(/* webpackChunkName: "patreon/logs" */ '../views/Patreon/SyncLogs.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/patreon/salaries',
    name: 'Salaries',
    component: () => import(/* webpackChunkName: "patreon/salaries" */ '../views/Patreon/Salaries.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/patreon/unsynced',
    name: 'Patreon Unsynced',
    component: () => import(/* webpackChunkName: "patreon/unsynced" */ '../views/Patreon/Unsynced.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/crafting/items',
    name: 'Crafting Items',
    component: () => import(/* webpackChunkName: "crafting/items" */ '../views/Crafting/Items.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/crafting/tasks',
    name: 'Crafting Tasks',
    component: () => import(/* webpackChunkName: "crafting/tasks" */ '../views/Crafting/Tasks.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/crafting/generators',
    name: 'Crafting Generators',
    component: () => import(/* webpackChunkName: "crafting/generators" */ '../views/Crafting/Generators.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/furniture',
    name: 'Furniture',
    component: () => import(/* webpackChunkName: "furniture" */ '../views/Furniture/Furniture.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/furniture/:furniture',
    name: 'Furniture Details',
    props: true,
    component: () => import(/* webpackChunkName: "furniture/details" */ '../views/Furniture/FurnitureDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/furniture-sales',
    name: 'Furniture Sales',
    component: () => import(/* webpackChunkName: "furniture-sales" */ '../views/Furniture/FurnitureSales.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/users',
    name: 'Users Home',
    component: () => import(/* webpackChunkName: "users/home" */ '../views/Users/Home.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/users/:user',
    name: 'User Details',
    props: true,
    component: () => import(/* webpackChunkName: "users/details" */ '../views/Users/UserDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/config',
    name: 'Configuration',
    component: () => import(/* webpackChunkName: "config" */ '../views/Config.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "tools" */ '../views/Tools.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/quests',
    name: 'Quests',
    component: () => import(/* webpackChunkName: "quests" */ '../views/Quests/Quests.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/quests/create',
    name: 'Create Quest',
    component: () => import(/* webpackChunkName: "quests/create" */ '../views/Quests/QuestCreate.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/quests/:quest',
    name: 'Quest Details',
    props: true,
    component: () => import(/* webpackChunkName: "quests/details" */ '../views/Quests/QuestDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/stories',
    name: 'Stories',
    component: () => import(/* webpackChunkName: "stories" */ '../views/Quests/Stories.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/stories/:story',
    name: 'Story Details',
    props: true,
    component: () => import(/* webpackChunkName: "stories/details" */ '../views/Quests/StoryDetails.vue'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/announcements',
    component: () => import(/* webpackChunkName: "announcements" */ '../views/Announcements.vue'),
    children: [
      {
        path: '',
        name: 'Announcements Home',
        component: () => import(/* webpackChunkName: "announcements/home" */ '../views/Announcements/Home.vue'),
      },
      {
        path: ':announcementId',
        name: 'Announcement Details',
        props: true,
        component: () => import(/* webpackChunkName: "announcements/details" */ '../views/Announcements/AnnouncementDetails.vue'),
      },
    ],
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "forums/downloads" */ '../views/Login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const context = {
    from,
    next,
    router,
    to,
  };

  let middleware = [];

  to.matched
    .filter((route) => route.meta.middleware)
    .forEach((route) => {
      if (Array.isArray(route.meta.middleware)) {
        middleware = [
          ...middleware,
          ...route.meta.middleware,
        ];
      } else {
        middleware.push(route.meta.middleware);
      }
    });

  if (middleware.length) {
    return middleware[0]({ ...context, next: nextFactory(context, middleware, 1) });
  }

  return next();
});

export default router;
